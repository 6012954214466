export interface DocumentApprovalEnabledSearchCriteria {
    all: boolean,
    billingType: boolean,
    chargeTypes: boolean,
    billingCycleCode: boolean,
    billToCompanies: boolean,
    vesselName: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    opsDateRange: boolean,
    genDateRange: boolean,
    genBy: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_DOCUMENT_APPROVAL_SEARCH_CRITERIA: DocumentApprovalEnabledSearchCriteria = {
    all: true,
    billingType: true,
    chargeTypes: true,
    billingCycleCode: true,
    billToCompanies: true,
    vesselName: true,
    vesselCode: true,
    voyageCode: true,
    genBy: true,
    opsDateRange: true,
    genDateRange: true
}
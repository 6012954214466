import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { DocumentApprovalRepoImpl } from "domain/repository/DocumentApproval/DocumentApprovalRepoImpl";
import { useDocumentApprovalTracked } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { DocumentApprovalVM } from "presentation/viewModel/DocumentApproval/DocumentApprovalVM";
import { useMemo } from "react";

export const useDocumentApprovalVM = () => {
    const [, setDocumentApprovalState] = useDocumentApprovalTracked();
    const documentApprovalVM = useMemo(() =>
        DocumentApprovalVM({
            dispatch: [setDocumentApprovalState],
            documentApprovalRepo: DocumentApprovalRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
        }), [setDocumentApprovalState])

    return documentApprovalVM
}


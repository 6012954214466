export interface DocumentApprovalSearchCriteria{
    
    billingType?: string | null,
    chargeTypes?: string[] | null,
    billingCycleCode?: string | null,
    billToCompanies?: string[] | null,
    vesselName?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    genDateFrom?: Date | null,
    genDateTo?: Date | null,
    genBy?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA : DocumentApprovalSearchCriteria = {

    billingType: null,
    chargeTypes: [],
    billingCycleCode: null,
    billToCompanies: [],
    vesselName: null,
    vesselCode: null,
    voyageCode: null,
    opsDateFrom: null,
    opsDateTo: null,
    genDateFrom: null,
    genDateTo: null,
    genBy: null,
}
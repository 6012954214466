import { DocumentApprovalSearchCriteria, EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA } from "domain/entity/DocumentApproval/DocumentApprovalSearchCriteria";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { DocumentApprovalRepository } from "domain/repository/DocumentApproval/DocumentApprovalRepo";
import _ from "lodash";
import { DocumentApprovalModel } from "presentation/model/DocumentApproval/DocumentApprovalModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface DocumentApprovalVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DocumentApprovalModel>> | ((value: SetStateAction<DocumentApprovalModel>) => void),
    ],
    documentApprovalRepo: DocumentApprovalRepository,
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
}

export const DocumentApprovalVM = ({dispatch,documentApprovalRepo,companyRepo,chargeTypeRepo}:DocumentApprovalVMProps) => {
    const [documentApprovalDispatch] = dispatch;


    const loadDropdownOption = async() => {

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let newCompanies = _.orderBy(companies, ["companyCode"]);
                const companyCodeDropdownOptions = newCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []

                documentApprovalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        );

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType"]);
                let chargeTypeDropdownOptions:DropdownProps[] = [];
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                });

                documentApprovalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                    }
                }))
            }
        )
        
    }

    const updateSelectedCharges = (rows:any[]) => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                documentApprovalState:{
                    ...prevState.documentApprovalState,
                    selectRows:rows
                },
            }
        })
    }

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        documentApprovalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        documentApprovalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value,
            }
        }))
    };

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onSearch = async (searchCriteria: DocumentApprovalSearchCriteria) => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: searchCriteria,
                documentApprovalDatas: [],
                documentApprovalState:{
                    ...prevState.documentApprovalState,
                    selectRows:[]
                },
            }
        })

        await documentApprovalRepo.getDocumentApprovalDatas(searchCriteria).then((data) => {
            if(data){
                documentApprovalDispatch(prevState => {
                    return {
                        ...prevState,
                        documentApprovalDatas: data,
                        searchCriteria: searchCriteria,
                        documentApprovalState:{
                            ...prevState.documentApprovalState,
                            selectRows:[]
                        },
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const onSearchCriteriaResetClick = () => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {...EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA}
            }
        })
    }

    const onSearchClick = () => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        }); 
    }
    const onApproveClick = async (ids: number[]) => {
        return await documentApprovalRepo.onApprove(ids);
    }
    const onRejectClick = async (ids: number[]) => {
        return await documentApprovalRepo.onDisapprove(ids);
    }

    const onShowLoading = () => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        documentApprovalDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    return {
        updateSelectedCharges: updateSelectedCharges,
        onDateRangeChange: onDateRangeChange,
        onSearchInputTextChange: onSearchInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearch: onSearch,
        onSearchClick: onSearchClick,
        loadDropdownOption: loadDropdownOption,
        onApproveClick: onApproveClick,
        onRejectClick: onRejectClick,
        onHideLoading: onHideLoading,
        onShowLoading: onShowLoading,

    }
}
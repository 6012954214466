export interface DocumentApprovalEntity{
    id: number,
    // previewRefNo?: string | null,
    docType?: string | null,
    handlingTerminal?: string | null,
    serviceCode?: string | null,
    billToCompany?: string | null,
    customerCode?: string | null,
    customerName1?: string | null,
    customerName2?: string | null,
    opsDate?: Date | null,
    chargeType?: string | null,
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    vesselName?: string | null,
    etd?: Date | null,
    errorMsg?: string | null,
    createdBy?: string | null,
    createdDateTime?: Date | null,
    currencyCode?: string | null,
    billingType?: string | null,
    billingCycleCode?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_DOCUMENT_APPROVAL_ENTITY : DocumentApprovalEntity = {
    id: 0,
    // previewRefNo: null,
    docType: null,
    handlingTerminal: null,
    serviceCode: null,
    billToCompany: null,
    customerCode: null,
    customerName1: null,
    customerName2: null,
    opsDate: null,
    chargeType: null,
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
    vesselName: null,
    etd: null,
    errorMsg: null,
    createdBy: null,
    createdDateTime: null,
    currencyCode: null,
    billingType: null,
    billingCycleCode: null,
}
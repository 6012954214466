
export const DocumentApprovalConstant = {
    Title: {
        TITLE: "Invoice and Credit Note Approval",
        APPROVE: "Approve",
        REJECT: "Reject",
        PREVIEW_DOCUMENT: "Preview Document",
    },
    Search: {
        SEARCH_CRITERIA: "Search Criteria",
        BILLING_TYPE: "Billing Type",
        CHARGE_TYPE: "Charge Type",
        BILLING_CYCLE_CODE: "Billing Cycle Code",
        BILL_TO_COMPANY: "Bill-To Company",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        VESSEL_NAME: "Vessel Name",
        OPS_DATE_FROM: "Ops Date(From)",
        OPS_DATE_TO: "Ops Date(To)",
        GEN_DATE_FROM: "Gen.Date(From)",
        GEN_DATE_TO: "Gen.Date(To)",
        GEN_BY: "Gen. By",
        OPS_DATE_RANGE: "Ops Date Range",
        GEN_DATE_RANGE: "Gen. Date Range",
    },
    Table: {
        PREVIEWREF_NO: "Preview Ref. No.",
        DOC_TYPE: "Doc. Type",
        HANDLING_TML: "Handling Tml.",
        SERVICE_CODE: "Service Code",
        BILL_TO_COMPANY: "Bill To Company",
        CUSTOMER_CODE: "Customer Code",
        CUSTOMER_NAME1: "Customer Name 1",
        CUSTOMER_NAME2: "Customer Name 2",
        OPS_DATE: "Ops. Date",
        CHARGE_TYPE: "Charge Type",
        CONSORTIUM_CODE: "Consortium Code",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        VESSEL_NAME: "Vessel Name",
        DEP_DATE_TIME: "Dep Date/Time",
        ERROR_MESSAGE: "Error Message",
        GENERATION_BY: "Generated by",
        GENERATION_DATE: "Generation Date",
        CONTRACT_CURRENCY: "Contract Currency",
        BILLING_TYPE: "Billing Type",
        BILLING_CYCLE_CODE: "Billing Cycle Code",

    }
}
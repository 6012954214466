import { DocumentApprovalSearchCriteria } from "domain/entity/DocumentApproval/DocumentApprovalSearchCriteria";
import { DocumentApprovalConstant } from "presentation/constant/DocumentApproval/DocumentApprovalConstant";
import { useDocumentApprovalVM } from "presentation/hook/DocumentApproval/useDocumentApprovalVM";
import { useDocumentApprovalTracked } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import DocumentApprovalSearchForm from "./DocumentApprovalSearchForm";

const DocumentApprovalSearchPanel = () => {
    const DOCUMENT_APPROVAL_CONSTANT = DocumentApprovalConstant.Search;
    const [documentApprovalState] = useDocumentApprovalTracked();
    const documentApprovalVM = useDocumentApprovalVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        documentApprovalVM.onSearchCriteriaResetClick();
    }, [documentApprovalVM]);
    
    const searchButtonClicked = useCallback((criteria:DocumentApprovalSearchCriteria) => {
        setIsLoading(true);
        documentApprovalVM.onSearch(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [documentApprovalVM])
    
    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{DOCUMENT_APPROVAL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={documentApprovalVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        <DocumentApprovalSearchForm/>
                        
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(documentApprovalState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}
export default DocumentApprovalSearchPanel;
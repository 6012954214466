import { EMPTY_DOCUMENT_APPROVAL_MODEL } from 'presentation/model/DocumentApproval/DocumentApprovalModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: DocumentApprovalProvider,
    useTracked: useDocumentApprovalTracked
} = createContainer(() => useState(EMPTY_DOCUMENT_APPROVAL_MODEL), {concurrentMode: true});
export { DocumentApprovalProvider, useDocumentApprovalTracked };


import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentApprovalProvider } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import DocumentApprovalMaintenance from "presentation/view/section/DocumentApproval/DocumentApprovalMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const DocumentApprovalContMain = () => {
    return <ANAInfoWrapper permission={Permission.DOCUMENT_APPROVAL}>
        <MessageBarWrapper>
            <DocumentApprovalProvider>
                <GridStyles/>
                <DocumentApprovalMaintenance/>
            </DocumentApprovalProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default DocumentApprovalContMain;
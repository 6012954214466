import { DocumentApprovalEntity, EMPTY_DOCUMENT_APPROVAL_ENTITY } from "domain/entity/DocumentApproval/DocumentApprovalEntity"
import { DocumentApprovalSearchCriteria, EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA } from "domain/entity/DocumentApproval/DocumentApprovalSearchCriteria"
import { DEFAULT_ENABLED_DOCUMENT_APPROVAL_SEARCH_CRITERIA, DocumentApprovalEnabledSearchCriteria } from "presentation/constant/DocumentApproval/DocumentApprovalEnabledSearchCriteria"
import { BaseViewChangeSate } from "../BaseViewChangeState"
import { DropdownProps } from "../DropdownProps"

export interface DocumentApprovalDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
}

export interface DocumentApprovalChangeState extends BaseViewChangeSate {
    currentSelectedData: DocumentApprovalEntity,
    selectRows: DocumentApprovalEntity[],
}

export interface DocumentApprovalModel {
    isLoading:boolean,
    dynamicOptions: DocumentApprovalDropdownOptions,
    documentApprovalDatas: DocumentApprovalEntity[],
    documentApprovalState: DocumentApprovalChangeState,
    searchCriteria: DocumentApprovalSearchCriteria,
    allFormState?: {[x:string]:string},
    enabledSearchCriteria: DocumentApprovalEnabledSearchCriteria,
    isShowCriteriaPanel: boolean,
}


export const EMPTY_DOCUMENT_APPROVAL_MODEL: DocumentApprovalModel = {
    documentApprovalDatas: [],
    documentApprovalState: {
        currentSelectedData: { ...EMPTY_DOCUMENT_APPROVAL_ENTITY },
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        selectRows: []
    },
    searchCriteria: { ...EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA },
    isLoading: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        billToCompDropdownOptions: []
    },
    enabledSearchCriteria: {...DEFAULT_ENABLED_DOCUMENT_APPROVAL_SEARCH_CRITERIA},
    isShowCriteriaPanel: false
}
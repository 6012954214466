import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_DOCUMENT_APPROVAL_COL_DEF, transferRowData } from "presentation/constant/DocumentApproval/DocumentApprovalColumnDefinition";
import { useDocumentApprovalVM } from "presentation/hook/DocumentApproval/useDocumentApprovalVM";
import { useDocumentApprovalTracked } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const DocumentApprovalTablePanel:React.FC = () => {
    const gridRef: any = useRef(null);
    const [ documentApprovalState ] = useDocumentApprovalTracked();
    const documentApprovalVM = useDocumentApprovalVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        documentApprovalVM.updateSelectedCharges(selectedRows);
      }, [documentApprovalVM]);
      
    const memoDocumentApprovalTable = useMemo(() => {

        return (
        <HPHTable
            id='change-key-data-table'
            isNewColumnSetting={true}
            columns={INITIAL_DOCUMENT_APPROVAL_COL_DEF}
            data={transferRowData(documentApprovalState.documentApprovalDatas??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        );
    },[documentApprovalState.documentApprovalDatas, handleSelectionChange])

    
    return <>
    {(documentApprovalState.isLoading)&& <Loader Indicator="Spinner" size="Medium" /> }
            <TableWrapper>
                {memoDocumentApprovalTable}
            </TableWrapper>
           </>;
}
export default memo(DocumentApprovalTablePanel);
import { DocumentApprovalEntity } from "domain/entity/DocumentApproval/DocumentApprovalEntity";
import { DocumentApprovalSearchCriteria } from "domain/entity/DocumentApproval/DocumentApprovalSearchCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import documentAxiosInstance from "../axios/documentAxiosInstance";
import { DocumentApprovalRepository } from "./DocumentApprovalRepo";

export const DocumentApprovalRepoImpl = () : DocumentApprovalRepository => {

    const getDocumentApprovalDatas = async(searchCriteria: DocumentApprovalSearchCriteria) : Promise<DocumentApprovalEntity[]> => {
        const url = "/v1/documentApprovalSearch";
        return axiosPostData(documentAxiosInstance, `${url}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const onDisapprove = async(ids: number[]) : Promise<boolean> => {
        const url = "/v1/onReject";
        return axiosPostData(documentAxiosInstance, `${url}`, ids).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return false;
        });
    }
    
    const onApprove = async(ids: number[]) : Promise<boolean> => {
        const url = "/v1/onApprove";
        return axiosPostData(documentAxiosInstance, `${url}`, ids).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return false;
        });
    }

    return {
        getDocumentApprovalDatas: getDocumentApprovalDatas,
        onApprove: onApprove,
        onDisapprove: onDisapprove,
    }
}
import { DocumentApprovalConstant } from "presentation/constant/DocumentApproval/DocumentApprovalConstant";
import { useDocumentApprovalVM } from "presentation/hook/DocumentApproval/useDocumentApprovalVM";
import { useDocumentApprovalTracked } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

const DocumentApprovalSearchForm = () => {    
    const DOCUMENT_APPROVAL_CONSTANT = DocumentApprovalConstant.Search;
    const [documentApprovalState] = useDocumentApprovalTracked();
    const documentApprovalSearchCriteria = documentApprovalState.searchCriteria;
    const documentApprovalVM = useDocumentApprovalVM();

    const memoBillingType = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_APPROVAL_CONSTANT.BILLING_TYPE}
                type="text"
                value={documentApprovalSearchCriteria.billingType || ''}
                onChange={(e: any) => documentApprovalVM.onSearchInputTextChange(e, 'billingType')}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.BILLING_TYPE, documentApprovalSearchCriteria.billingType, documentApprovalVM])

    const memoBillingCycleCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_APPROVAL_CONSTANT.BILLING_CYCLE_CODE}
                type="text"
                value={documentApprovalSearchCriteria.billingCycleCode || ''}
                onChange={(e: any) => documentApprovalVM.onSearchInputTextChange(e, 'billingCycleCode')}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.BILLING_CYCLE_CODE, documentApprovalSearchCriteria.billingCycleCode, documentApprovalVM])

    const memoChargeType = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={DOCUMENT_APPROVAL_CONSTANT.CHARGE_TYPE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={documentApprovalSearchCriteria.chargeTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {
                    documentApprovalVM.onMultipleDropdownChange(e, 'chargeTypes')
                }}
                options={documentApprovalState.dynamicOptions.chargeTypeDropdownOptions}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.CHARGE_TYPE, documentApprovalSearchCriteria.chargeTypes, documentApprovalState.dynamicOptions.chargeTypeDropdownOptions, documentApprovalVM])
    
    const memobillToCompany = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={DOCUMENT_APPROVAL_CONSTANT.BILL_TO_COMPANY}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={documentApprovalSearchCriteria.billToCompanies?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {
                    documentApprovalVM.onMultipleDropdownChange(e, 'billToCompanies')
                }}
                options={documentApprovalState.dynamicOptions.billToCompDropdownOptions}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.BILL_TO_COMPANY, documentApprovalSearchCriteria.billToCompanies, documentApprovalState.dynamicOptions.billToCompDropdownOptions, documentApprovalVM])
    
    const memoVesselName = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_APPROVAL_CONSTANT.VESSEL_NAME}
                type="text"
                value={documentApprovalSearchCriteria.vesselName || ''}
                onChange={(e: any) => documentApprovalVM.onSearchInputTextChange(e, 'vesselName')}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.VESSEL_NAME, documentApprovalSearchCriteria.vesselName, documentApprovalVM])

    const memoVesselCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_APPROVAL_CONSTANT.VESSEL_CODE}
                type="text"
                value={documentApprovalSearchCriteria.vesselCode || ''}
                onChange={(e: any) => documentApprovalVM.onSearchInputTextChange(e, 'vesselCode')}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.VESSEL_CODE, documentApprovalSearchCriteria.vesselCode, documentApprovalVM])

    const memoVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_APPROVAL_CONSTANT.VOYAGE_CODE}
                type="text"
                value={documentApprovalSearchCriteria.voyageCode || ''}
                onChange={(e: any) => documentApprovalVM.onSearchInputTextChange(e, 'voyageCode')}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.VOYAGE_CODE, documentApprovalSearchCriteria.voyageCode, documentApprovalVM])

    const memoOpsDateFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={DOCUMENT_APPROVAL_CONSTANT.OPS_DATE_RANGE}
                    width='390px'
                    fieldNames={{startField:"opsDateFrom", endField:"opsDateTo"}}
                    dateRange={{startDate:documentApprovalSearchCriteria.opsDateFrom, endDate:documentApprovalSearchCriteria.opsDateTo}}
                    onDatesChange={documentApprovalVM.onDateRangeChange}
                />                
            </div>
        , [DOCUMENT_APPROVAL_CONSTANT.OPS_DATE_RANGE, documentApprovalSearchCriteria.opsDateFrom, documentApprovalSearchCriteria.opsDateTo, documentApprovalVM.onDateRangeChange])
    
        const memoGenDateFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={DOCUMENT_APPROVAL_CONSTANT.GEN_DATE_RANGE}
                    width='390px'
                    fieldNames={{startField:"genDateFrom", endField:"genDateTo"}}
                    dateRange={{startDate:documentApprovalSearchCriteria.genDateFrom, endDate:documentApprovalSearchCriteria.genDateTo}}
                    onDatesChange={documentApprovalVM.onDateRangeChange}
                />                
            </div>
        , [DOCUMENT_APPROVAL_CONSTANT.GEN_DATE_RANGE, documentApprovalSearchCriteria.genDateFrom, documentApprovalSearchCriteria.genDateTo, documentApprovalVM.onDateRangeChange])

    const memoGenBy = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_APPROVAL_CONSTANT.GEN_BY}
                type="text"
                value={documentApprovalSearchCriteria.genBy || ''}
                onChange={(e: any) => documentApprovalVM.onSearchInputTextChange(e, 'genBy')}/>
        </div>
    , [DOCUMENT_APPROVAL_CONSTANT.GEN_BY, documentApprovalSearchCriteria.genBy, documentApprovalVM])
    
    

    return <>        
        
        <CriteriaItemContainer>
            {documentApprovalState.enabledSearchCriteria.billingType ? memoBillingType : null}
            {documentApprovalState.enabledSearchCriteria.billingCycleCode ? memoBillingCycleCode : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentApprovalState.enabledSearchCriteria.chargeTypes ? memoChargeType : null}
            {documentApprovalState.enabledSearchCriteria.billToCompanies ? memobillToCompany : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentApprovalState.enabledSearchCriteria.vesselCode ? memoVesselCode : null}
            {documentApprovalState.enabledSearchCriteria.vesselName ? memoVesselName : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentApprovalState.enabledSearchCriteria.voyageCode ? memoVoyageCode : null}
            {documentApprovalState.enabledSearchCriteria.genBy ? memoGenBy : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentApprovalState.enabledSearchCriteria.opsDateRange ? memoOpsDateFromTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {documentApprovalState.enabledSearchCriteria.genDateRange ? memoGenDateFromTo : null}
        </CriteriaItemContainer>

    </>;
}

export default memo(DocumentApprovalSearchForm);